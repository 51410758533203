import { decorate, observable, action, autorun } from "mobx";
import AuthStore from "./AuthStore";
import { request } from "../utils";

class GiveawayStore {
  constructor() {
    autorun(() => {
      if (AuthStore.authenticated) this.fetchStats();
      else this.clear();
    });
  }

  loading = true;
  entries = [];

  async fetchStats() {
    const entries = await request.get("/v1/giveaway");
    this.entries = entries;
    this.loading = false;
  }

  async clear() {
    this.entries = {};
  }
}

const DecoratedGiveawayStore = decorate(GiveawayStore, {
  loading: observable,
  entries: observable,
  fetchStats: action,
  clear: action
});

export default new DecoratedGiveawayStore();
