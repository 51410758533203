import axios from "axios";
import Amplify, { API } from "aws-amplify";
import { AMPLIFY_CONFIG, API_NAME } from "../constants";

const makeWrappedMethod = method => (url, config = {}) => {
  Amplify.configure(AMPLIFY_CONFIG);
  const amplifyMethod = method === "delete" ? "del" : method;
  return API[amplifyMethod](API_NAME, url, config);
};

const wrapper = {
  post: makeWrappedMethod("post"),
  get: makeWrappedMethod("get"),
  put: makeWrappedMethod("put"),
  delete: makeWrappedMethod("delete"),

  bare: {
    post: axios.post,
    get: axios.get,
    put: axios.put,
    delete: axios.delete
  }
};

export default wrapper;
