import React, { useState } from "react";
import { observer } from "mobx-react";
import useOnEnterPressed from "../../hooks/useOnEnterPressed";
import AuthStore from "../../stores/AuthStore";
import "./Auth.scss";

function Auth() {
  const [username, setUsername] = useState("");
  const updateUsername = ({ target }) => setUsername(target.value);

  const [password, setPassword] = useState("");
  const updatePassword = ({ target }) => setPassword(target.value);

  const logIn = () => AuthStore.logIn(username, password);
  useOnEnterPressed(logIn);

  return (
    <div className="auth">
      <div className="inputs-container">
        <div className="auth-square-title">The Agent Reviews Admin</div>
        <input className="auth-input" type="email" placeholder="Email" value={username} onChange={updateUsername} />
        <input
          className="auth-input"
          type="password"
          placeholder="Password"
          value={password}
          onChange={updatePassword}
        />
        <div className="login-button" onClick={logIn}>
          LOG IN
        </div>
      </div>
    </div>
  );
}

export default observer(Auth);
