import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import ConfigStore from "../../stores/ConfigStore";
import AnalyticsStore from "../../stores/AnalyticsStore";
import Loader from "../Loader";
import "./PageViews.scss";

function AnalyticSquare({ title, host, withDetailPageReviews, withoutDetailPageReviews, total }) {
  return (
    <div className="analytic-square">
      <div>{title}</div>
      <div className="host">(from {host.replace(/https?:\/\//, "")})</div>
      <div className="details">
        <div className="detail">
          <div>With Detail Page Reviews Visible:</div>
          <div>{withDetailPageReviews || 0}</div>
        </div>
        <div className="detail">
          <div>Without Detail Page Reviews Visible:</div>
          <div>{withoutDetailPageReviews || 0}</div>
        </div>
      </div>
      <div className="bottom-bar">
        <div className="total">Total: {total}</div>
      </div>
    </div>
  );
}

function PageViews() {
  const [localPercentage, setLocalPercentage] = useState(ConfigStore.percentage * 100);
  const updateLocalPercentage = ({ target }) => {
    const newPercentage = Math.min(Math.max(Number(target.value) || 0, 0), 100);
    setLocalPercentage(newPercentage);
  };
  useEffect(() => {
    if (ConfigStore.percentage && localPercentage.toFixed(2) !== (ConfigStore.percentage * 100).toFixed(2)) {
      setLocalPercentage(ConfigStore.percentage * 100);
    }
  }, [ConfigStore.percentage]); // eslint-disable-line

  const disabled = localPercentage.toFixed(2) === (ConfigStore.percentage * 100).toFixed(2);
  const savePercentage = async () => {
    if (!disabled) {
      await ConfigStore.setABPercentage(localPercentage / 100);
    }
  };

  const analyticSquares = (AnalyticsStore.analytics || []).map(analytic => {
    return <AnalyticSquare {...analytic} key={analytic.id} />;
  });

  if (ConfigStore.loading || AnalyticsStore.loading) {
    return (
      <div className="loading-wrapper">
        <Loader />
      </div>
    );
  }

  return (
    <div className="page-views">
      {/* <div className="page-header">
        <div className="page-title">A/B Test Percentage</div>
      </div>
      <div className="percentage-input-row">
        <div className="input-label">Percentage of visitors that should see reviews on an agent's detail page:</div>
        <input placeholder="0-100" value={localPercentage} onChange={updateLocalPercentage} />
        <div className="input-label">%</div>
        <div className={`update-button${disabled ? " disabled" : ""}`} onClick={savePercentage}>
          Update
        </div>
      </div> */}
      <div className="page-header">
        <div className="page-title">Page Views</div>
        <div className="action-buttons">{/* <div className="button">
            + Add Analytic
          </div> */}</div>
      </div>
      <div className="analytic-squares">{analyticSquares}</div>
    </div>
  );
}

export default observer(PageViews);
