import { decorate, observable, action } from "mobx";
import Amplify, { Auth } from "aws-amplify";
import { navigate } from "@reach/router";
import { COGNITO_CONFIG } from "../constants";

class AuthStore {
  constructor() {
    Amplify.configure(COGNITO_CONFIG);
    this.checkForAuthenticatedUser();
  }

  authDetermined = false;
  authenticated = false;
  user = {};

  async checkForAuthenticatedUser() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      if (user) {
        this.authenticated = true;
        this.user = user;
      } else {
        this.authenticated = false;
        this.user = {};
      }
    } catch (err) {
      // This also means user isn't authenticated
      this.authenticated = false;
    }

    this.authDetermined = true;
  }

  async logIn(username, password) {
    try {
      const authResponse = await Auth.signIn(username || "-", password || "-");

      if (authResponse.challengeName === "NEW_PASSWORD_REQUIRED") {
        this.challengeUser = authResponse;
        return navigate(`/setup`);
      } else {
        const user = await Auth.currentAuthenticatedUser();
        this.user = user;
        this.authenticated = true;
      }
    } catch (err) {
      console.warn(err);
      this.authenticated = false;
    }
  }

  challengeUser;

  async setupPassword(newPassword) {
    try {
      const user = await Auth.completeNewPassword(this.challengeUser, newPassword);
      this.challengeUser = null;
      this.user = user;
      this.authenticated = true;
    } catch (err) {
      console.warn(err);
      this.authenticated = false;
    }
  }

  async logOut() {
    try {
      await Auth.signOut();
      this.user = {};
      this.authenticated = false;
    } catch (err) {
      console.warn(err);
    }
  }
}

const DecoratedAuthStore = decorate(AuthStore, {
  authDetermined: observable,
  authenticated: observable,
  user: observable,
  checkForAuthenticatedUser: action,
  logIn: action,
  challengeUser: observable,
  setupPassword: action,
  logOut: action
});

export default new DecoratedAuthStore();
