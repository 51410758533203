import { decorate, observable, action, autorun } from "mobx";
import AuthStore from "./AuthStore";
import { request } from "../utils";

class ConfigStore {
  constructor() {
    autorun(() => {
      if (AuthStore.authenticated) this.fetchConfig();
      else this.clear();
    });
  }

  loading = true;

  percentage;

  async fetchConfig() {
    const { percentage } = await request.get("/v1/config");
    this.percentage = percentage;
    this.loading = false;
  }

  async setABPercentage(percentage) {
    this.loading = true;
    const prePercentage = this.percentage;
    this.percentage = percentage;
    try {
      await request.post("/v1/config", { body: { percentage } });
      this.loading = false;
    } catch (err) {
      console.warn(err);
      this.percentage = prePercentage;
      this.loading = false;
    }
  }

  async clear() {
    this.percentage = null;
  }
}

const DecoratedConfigStore = decorate(ConfigStore, {
  loading: observable,
  percentage: observable,
  fetchConfig: action,
  setABPercentage: action,
  clear: action
});

export default new DecoratedConfigStore();
