import React from "react";
import AuthStore from "../../stores/AuthStore";
import logo from "../../assets/testlogo.png";
import "./Header.scss";

const logOut = () => AuthStore.logOut();

export default function Header() {
  return (
    <div className="header">
      <div className="left-items">
        <img src={logo} className="logo" alt="The Agent Ratings logo" />
      </div>
      <div className="right-items">
        <svg className="log-out-icon" viewBox="0 0 24 24" onClick={logOut}>
          <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
          <polyline points="16 17 21 12 16 7" />
          <line x1="21" y1="12" x2="9" y2="12" />
        </svg>
      </div>
    </div>
  );
}
