import { decorate, observable, action, computed, autorun } from "mobx";
import AuthStore from "./AuthStore";
import { request } from "../utils";

class AnalyticsStore {
  constructor() {
    autorun(() => {
      if (AuthStore.authenticated) this.fetchAnalytics();
      else this.clear();
    });
  }

  loading = true;
  rawAnalytics = [];

  get analytics() {
    const grouped = this.rawAnalytics
      .slice()
      .map(a => ({ ...a, title: a.type }))
      .reduce((acc, next) => {
        const key = next.detailPageReviewsEnabled ? "withDetailPageReviews" : "withoutDetailPageReviews";
        if (acc[next.host + next.title]) {
          acc[next.host + next.title] = {
            ...acc[next.host + next.title],
            total: acc[next.host + next.title].total + next.total,
            [key]: next.total
          };
        } else {
          acc[next.host + next.title] = {
            ...next,
            [key]: next.total
          };
        }

        return acc;
      }, {});

    return Object.values(grouped)
      .slice()
      .sort((a, b) => {
        return a.host + a.title > b.host + b.title ? 1 : -1;
      });
  }

  async fetchAnalytics() {
    const analytics = await request.get("/v1/analytics");
    this.rawAnalytics = analytics;
    this.loading = false;
  }

  async clear() {
    this.rawAnalytics = [];
  }
}

const DecoratedAnalyticsStore = decorate(AnalyticsStore, {
  loading: observable,
  rawAnalytics: observable,
  analytics: computed,
  fetchAnalytics: action,
  clear: action
});

export default new DecoratedAnalyticsStore();
