import React from "react";
import { observer } from "mobx-react";
import { Location, Router, Redirect, Link, useLocation } from "@reach/router";
import AuthStore from "../stores/AuthStore";
import Loader from "../components/Loader";
import Header from "../components/Header";
import Analytics from "../components/@Analytics";
import Giveaway from "../components/@Giveaway";
import PageViews from "../components/@PageViews";
import Auth from "../components/@Auth";
import SetupPassword from "../components/@SetupPassword";
import "./App.scss";

function Sidebar() {
  const { pathname: path } = useLocation();

  return (
    <div className="sidebar">
      <Link className={`sidebar-link${path === "/analytics" ? " active" : ""}`} to="/analytics">
        Analytics
      </Link>
      <Link className={`sidebar-link${path === "/giveaway" ? " active" : ""}`} to="/giveaway">
        Giveaway Entries
      </Link>
      <Link className={`sidebar-link${path === "/pageviews" ? " active" : ""}`} to="/pageviews">
        Page Views
      </Link>
    </div>
  );
}

function App() {
  if (!AuthStore.authDetermined) {
    return (
      <div className="root loading">
        <Loader />
      </div>
    );
  }

  if (AuthStore.authenticated) {
    return (
      <Location>
        <div className="root">
          <Header />
          <div className="app-container">
            <Sidebar />
            <Router>
              <Analytics path="/analytics" />
              <Giveaway path="/giveaway" />
              <PageViews path="/pageviews" />
              <Redirect from="*" to="/analytics" noThrow />
            </Router>
          </div>
        </div>
      </Location>
    );
  }

  const setupRoute = AuthStore.challengeUser ? <SetupPassword path="/setup" /> : null;
  return (
    <div className="root">
      <Router>
        <Auth path="/" />
        {setupRoute}
        <Redirect from="*" to="/" noThrow />
      </Router>
    </div>
  );
}

export default observer(App);
