export const API_HOST = "https://qsmx82r4f5.execute-api.us-east-1.amazonaws.com/dev";
export const API_NAME = "dev-agent-api";

export const COGNITO_CONFIG = {
  identityPoolId: "us-east-1:3a61aa98-f1e8-40d1-bb5a-aa28b4de94a9",
  region: "us-east-1",
  userPoolId: "us-east-1_qP3Gdga14",
  userPoolWebClientId: "kbvchjijdlnjjdiba83gb05vj",
  authenticationFlowType: "USER_PASSWORD_AUTH"
};

export const API_CONFIG = { endpoints: [{ name: API_NAME, endpoint: API_HOST }] };
export const AMPLIFY_CONFIG = { Auth: COGNITO_CONFIG, API: API_CONFIG };
