import { decorate, observable, action, autorun } from "mobx";
import AuthStore from "./AuthStore";
import { request } from "../utils";

class StatisticsStore {
  constructor() {
    autorun(() => {
      if (AuthStore.authenticated) this.fetchStats();
      else this.clear();
    });
  }

  loading = true;
  stats = {};

  async fetchStats() {
    const stats = await request.get("/v1/analytics/statistics");
    this.stats = stats;
    this.loading = false;
  }

  async clear() {
    this.stats = {};
  }
}

const DecoratedStatisticsStore = decorate(StatisticsStore, {
  loading: observable,
  stats: observable,
  fetchStats: action,
  clear: action
});

export default new DecoratedStatisticsStore();
