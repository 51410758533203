import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import ConfigStore from "../../stores/ConfigStore";
import StatisticsStore from "../../stores/StatisticsStore";
import Loader from "../Loader";
import "./Analytics.scss";

function AnalyticSquare({ title, value }) {
  return (
    <div className="analytic-square">
      <div>{title}</div>
      <div className="metric">{value}</div>
    </div>
  );
}

function Analytics() {
  const { totalAgents, totalReviews, avgReviewsPerAgent } = StatisticsStore?.stats || {};
  if (ConfigStore.loading || StatisticsStore.loading) {
    return (
      <div className="loading-wrapper">
        <Loader />
      </div>
    );
  }

  return (
    <div className="analytics">
      <div className="page-header">
        <div className="page-title">Analytics</div>
      </div>
      <div className="analytic-squares">
        <AnalyticSquare title="Total Agents" value={totalAgents} />
        <AnalyticSquare title="Total Reviews" value={totalReviews} />
        <AnalyticSquare title="Avg. Reviews per Agent" value={avgReviewsPerAgent.toFixed(1)} />
      </div>
    </div>
  );
}

export default observer(Analytics);
