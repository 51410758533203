import React from "react";
import { observer } from "mobx-react";
import { parseISO, format } from "date-fns";
import ConfigStore from "../../stores/ConfigStore";
import GiveawayStore from "../../stores/GiveawayStore";
import Loader from "../Loader";
import "./Giveaway.scss";

function EntryRow({ email, name, date }) {
  return (
    <tr className="entry-row">
      <td>{email}</td>
      <td>{name}</td>
      <td>{format(parseISO(date), "MMM d, yyyy")}</td>
    </tr>
  );
}

function Giveaway() {
  if (ConfigStore.loading || GiveawayStore.loading) {
    return (
      <div className="loading-wrapper">
        <Loader />
      </div>
    );
  }

  const entryRows = GiveawayStore?.entries?.map((entry, i) => <EntryRow {...entry} key={i} />);

  return (
    <div className="giveaway">
      <div className="page-header">
        <div className="page-title">Giveaway Entries</div>
      </div>
      <table className="entries">
        <thead>
          <tr className="entry-row entries-header">
            <th>Email</th>
            <th>Name</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>{entryRows}</tbody>
      </table>
    </div>
  );
}

export default observer(Giveaway);
